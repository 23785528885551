import Constants from '../constants.js'

import AuthUtil from '../authUtil'
import FirestoreUtil from '../firestoreUtil'

import { doc, getDoc, query, collection, where, documentId, getCountFromServer, Timestamp } from "firebase/firestore";

import axios from 'axios'

export default {
  async getDeveloperApps () {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const developerAppsURL = `${Constants.API_BASE_PATH}/developer/apps`

    const response = await axios.get(developerAppsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getSysAdminApps () {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const appsURL = `${Constants.API_BASE_PATH}/developer/admin/apps`
    const response = await axios.get(appsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  appRef (appId) {
    return doc(FirestoreUtil.db, `apps`, appId)
  },
  appTaxonsRef (appId) {
    return collection(FirestoreUtil.db, `apps/${appId}/taxons`)
  },
  appItemsChunkRef (appId, itemIds) {
    return query(collection(FirestoreUtil.db, `apps/${appId}/items`), where(documentId(), 'in', itemIds))
  },
  insightDataChunkRef (appId, region, productKeys) {
    return query(collection(FirestoreUtil.db, `apps/${appId}/insights/${region}/items`), where(documentId(), 'in', productKeys))
  },

  async appComparisonStatsCount (appId, eventName, timerange) {
    
    let countQuery = query(collection(FirestoreUtil.db, `apps/${appId}/comparison-stats`), where('eventType', '==', eventName))
    if (timerange) {
      const startDate = new Date()
      startDate.setDate(startDate.getDate() - timerange);
      const startTS = Timestamp.fromDate(startDate);
      const endTS = Timestamp.fromDate(new Date())
      countQuery = query(collection(FirestoreUtil.db, `apps/${appId}/comparison-stats`), where('eventType', '==', eventName), where('timeStamp', '>', startTS), where('timeStamp', '<', endTS))
    }
    const snapshot = await getCountFromServer(countQuery);
    return snapshot.data().count
  },

  // async appComparisonCount (appId) {
  //   let countQuery = query(collection(FirestoreUtil.db, `apps/${appId}/comparison-stats`), where('eventType', '==', 'comparisonEvent'))
  //   const snapshot = await getCountFromServer(countQuery);
  //   return snapshot.data().count
  // },
  // async appComparisonCartAddsCount (appId) {
  //   const countQuery = query(collection(FirestoreUtil.db, `apps/${appId}/comparison-stats`), where('eventType', '==', 'addedToCartEvent'))
  //   const snapshot = await getCountFromServer(countQuery);
  //   return snapshot.data().count
  // },
  // async appComparisonCartViewsCount (appId) {
  //   const countQuery = query(collection(FirestoreUtil.db, `apps/${appId}/comparison-stats`), where('eventType', '==', 'visitedCartEvent'))
  //   const snapshot = await getCountFromServer(countQuery);
  //   return snapshot.data().count
  // },
  // async appComparisonSelectionsCount (appId) {
  //   const countQuery = query(collection(FirestoreUtil.db, `apps/${appId}/comparison-stats`), where('eventType', '==', 'selectedEvent'))
  //   const snapshot = await getCountFromServer(countQuery);
  //   return snapshot.data().count
  // },
  async appAttributeValuesCapturedSum (appId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let dataURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/analytics/features`
    const response = await axios.get(dataURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getSysAdminAppInsightGaps (appId, hostname, region) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let dataURL = `${Constants.API_BASE_PATH}/developer/admin/apps/${appId}/insightgaps`
    let hasQueries = false
    if (hostname) {
      dataURL = `${dataURL}?hostname=${hostname}`
      hasQueries = true
    }
    if (region) {
      dataURL = `${dataURL}${ hasQueries ? '&' : '?' }region=${region}`
    }
    const response = await axios.get(dataURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getSysAdminNotStockedData (hostname, productCodes) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let response = null
    if (productCodes) {
      const stockDataURL = `${Constants.API_BASE_PATH}/developer/admin/insights/notstockedbatch`
      response = await axios.post(stockDataURL, {hostname: hostname, codes: productCodes}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })
    } else {
      const stockDataURL = `${Constants.API_BASE_PATH}/developer/admin/insights/notstocked?hostname=${hostname}`
      response = await axios.get(stockDataURL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })
    }

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async setCompetitorNotStocked (appId, hostname, codeType, code, verifiedNotStocked) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const stockDataURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/insights/codenotstocked`
    const postBody = {
      hostname: hostname,
      codeType: codeType,
      codeValue: code,
      verifiedNotStocked: verifiedNotStocked
    }
    const response = await axios.post(stockDataURL, postBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async deleteNotStockedData (hostname, codeType, code, verifiedNotStocked) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const stockDataURL = `${Constants.API_BASE_PATH}/developer/admin/insights/clearnotstocked`
    const postBody = {
      hostname: hostname,
      codeType: codeType,
      codeValue: code,
      verifiedNotStocked: verifiedNotStocked
    }
    const response = await axios.post(stockDataURL, postBody, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getAppInvitations () {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const developerAppInvitationsURL = `${Constants.API_BASE_PATH}/developer/invitations`
    const response = await axios.get(developerAppInvitationsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getAppInsightRefreshJobs (appId, region) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const refreshJobsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/insights/${region}/refreshjobs`
    const response = await axios.get(refreshJobsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async addItemToRefreshJob (appId, jobId, itemId) {
  
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const addItemURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/insightrefreshjobs/${jobId}/additem`
    const response = await axios.post(addItemURL, {itemId: itemId}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data.added
    } else {
      return false
    }
  },
  async removeItemFromRefreshJob (appId, jobId, itemId) {
  
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const addItemURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/insightrefreshjobs/${jobId}/removeitem`
    const response = await axios.post(addItemURL, {itemId: itemId}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async startAppInsightRefreshJob (appId, jobId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const refreshJobURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/insightrefreshjobs/${jobId}/run`
    const response = await axios.post(refreshJobURL, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async insightJobItemsCount (appId, jobId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const itemCountURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/insightrefreshjobs/${jobId}/itemcount`
    const response = await axios.get(itemCountURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data.count
    } else {
      return null
    }
  },
  async updateAppInvitation (payload) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const developerAppInvitationsURL = `${Constants.API_BASE_PATH}/developer/invitations/`
    const response = await axios.post(developerAppInvitationsURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async newDeveloperApp (payload, callback) {
    // const newAppData = {
    //   id: 'not-real-id',
    //   appKey: 'todo-generate-an-id-on-server',
    //   owner: 'also-added-on-server',
    //   verifiedDomain: false,
    //   ...payload
    // }
    // callback(newAppData)

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const developerAppsURL = `${Constants.API_BASE_PATH}/developer/apps`
    axios.post(developerAppsURL, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      if (response && response.status === 200 && response.data && response.data.status === 'success') {
        // const appData = {id: response.data.appId, ...response.data.appData}
        callback(response.data)
      }
    })
    .catch(function (/*error*/) {
      // handle error
      // console.log(error)
      callback(null)
    })
    .then(function () {
      // always executed
    })
  },
  async getDeveloperApp (appId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const developerAppURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}`
    const response = await axios.get(developerAppURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }

  },
  async updateDeveloperAppName (appId, newName) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const updateAppDetailsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}`

    const changes = {name: newName}
    
    const response = await axios.post(updateAppDetailsURL, changes, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data && response.data.status === 'success') {
      return response.data
    } else {
      return null
    }

  },
  async updateDeveloperAppStoreDetails (appId, updates) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const updateAppDetailsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}`

    const changes = {}

    if (typeof updates.storeName !== 'undefined') {
      changes.storeName = updates.storeName
    }
    if (typeof updates.storeImg !== 'undefined') {
      changes.storeImg = updates.storeImg
    }

    if (typeof updates.storeDescription !== 'undefined') {
      changes.storeDescription = updates.storeDescription
    }

    if (typeof updates.catalogFeedConfig !== 'undefined') {
      changes.catalogFeedConfig = updates.catalogFeedConfig
    }

    let response = null
    if (Object.keys(changes).length > 0) {
      response = await axios.post(updateAppDetailsURL, changes, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })
    }

    if (response && response.status === 200 && response.data && response.data.status === 'success') {
      return response.data
    } else {
      return null
    }

  },
  async getAppFeedCategoryKeys (appId) {
    let res = null
    const feedKeysDoc = await getDoc(doc(FirestoreUtil.db, `app-feed-keys`, appId));
    if (feedKeysDoc.exists()) {
      const docData = feedKeysDoc.data()
      if (docData.feedCategories) {
        // res = docData.feedCategories
        res = docData
      }
    }
    return res
  },
  async inviteMember (appId, memberInviteData) {
    // const shareMemberData = {userName: this.userName, roles: ['editor']}

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postNewMemberURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/members`
    const res = await axios.post(postNewMemberURL, memberInviteData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      },
    });

    if (res && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async removeMember (appId, memberId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteMemberURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/members/${memberId}`
    const res = await axios.delete(deleteMemberURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      },
    });

    if (res && res.status === 200 && res.data && res.data.status === 'success') {
      return true
    } else {
      return false
    }
  },
  async deleteDeveloperApp (appId, callback) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const developerAppURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}`
    axios.delete(developerAppURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      // console.log(response)
      callback(response.data)
    })
    .catch(function (/*error*/) {
      // handle error
      // console.log(error)
      callback(null)
    })
  },
  async newAppTaxon (appId, taxonData, callback) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const appTaxonUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons`
    axios.post(appTaxonUrl, taxonData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      if (callback && response && response.status === 200 && response.data && response.data.status === 'success') {
        callback(response.data)
      }
    })
    .catch(function (/*error*/) {
      // handle error
      // console.log(error)
      if (callback) {
        callback(null)
      }
    })
  },
  async deleteAppTaxon (appId, taxonId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteAppTaxonURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}`
    const response = await axios.delete(deleteAppTaxonURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    
    if(response && response.data) {
      
      return response.data
    } else {
      return null
    }
  },
  async updateTaxonData (appId, taxonId, payload) {
    // payload = {taxonId: this.newTaxon.id, taxonData: taxonData}

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const appTaxonUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}`
    const body = {}
    if (payload.criteria) {
      body.criteria = payload.criteria
    }
    if (payload.taxonURLs) {
      body.taxonURLs = payload.taxonURLs
    }
    if (payload.modelSettings) {
      body.modelSettings = payload.modelSettings
    }
    const response = await axios.post(appTaxonUrl, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    
    if (response && response.status === 200 && response.data && response.data.status === 'success') {
      return response.data
    } else {
      return null
    }
  },
  async updateServiceMode (appId, serviceId, taxonId, settings) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let updateServiceSettingsUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}`
    let body = {}
    if (taxonId) {
      updateServiceSettingsUrl = `${updateServiceSettingsUrl}/taxons/${taxonId}/services/${serviceId}/mode`
      body.mode = settings.taxonModes[taxonId]
    } else {
      updateServiceSettingsUrl = `${updateServiceSettingsUrl}/services/${serviceId}/mode`
      body.mode = settings.mode
    }

    const response = await axios.post(updateServiceSettingsUrl, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data && response.data.status === 'success') {
      return response.data
    } else {
      return null
    }

  },
  async updateComparisonSettings (appId, taxonId, settingKey, settingState) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const updateSettingsUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/services/comparison/settings`

    const response = await axios.post(updateSettingsUrl, {settingKey: settingKey, settingState: settingState}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data && response.data.status === 'success') {
      return response.data
    } else {
      return null
    }

  },
  async updateTaxonSearchSettings (appId, taxonId, settingKey, settingState) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const updateSettingsUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/services/search/settings`

    const response = await axios.post(updateSettingsUrl, {settingKey: settingKey, settingState: settingState}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data && response.data.status === 'success') {
      return response.data
    } else {
      return null
    }

  },
  async updateSearchIndexAllSetting (appId, settingState) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const updateSettingsUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/services/search/settings`

    const response = await axios.post(updateSettingsUrl, {settingKey: 'indexEverything', settingState: settingState}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data && response.data.status === 'success') {
      return response.data
    } else {
      return null
    }

  },
  async getTaxonSelectors (appId, taxonId, criteria) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const developerAppsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/selectors`
    const body = {criteria: criteria}
    const response = await axios.post(developerAppsURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return []
    }
  },
  async invalidateTaxon (appId, taxonId, callback) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const invalidateTaxonURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxons/${taxonId}/invalidate`
    axios.get(invalidateTaxonURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      // console.log(response)
      callback(response.data)
    })
    .catch(function (/*error*/) {
      // handle error
      // console.log(error)
      callback(null)
    })
    .then(function () {
      // always executed
    })
  },
  async getDeveloperAppDecisionBoards (appId) {
    // /developer/apps/:app/taxonboards
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const invalidateTaxonURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/taxonboards`
    const response = await axios.get(invalidateTaxonURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200 && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async getDeveloperAppCategories (appId, callback) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const fetchURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/categories`
    axios.get(fetchURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      callback(response.data)
      // if (response && response.data && response.data.status === 'success') {
      //   callback(response.data.categories)
      // } else {
      //   callback(null)
      // }
    })
    .catch(function (/*error*/) {
      // handle error
      // console.log(error)
      callback(null)
    })

  },
  async getDeveloperAppVerificationContent (appId, path, protocol, callback) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let fetchURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/verify`
    if (path) {
      fetchURL = fetchURL + `?path=${path}`
    }
    if (protocol) {
      const querySymbol = path ? '&' : '?'
      fetchURL = fetchURL + `${querySymbol}protocol=${protocol}`
    }
    axios.get(fetchURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      callback(response.data)
    })
    .catch(function (/*error*/) {
      callback(null)
    })

  },
  async getAppComparisonSDKStats (appId, startDate, endDate) {
    
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let analyticsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/analytics/comparesdk`
    if (startDate && endDate) {
      analyticsURL = `${analyticsURL}?startDate=${startDate}&endDate=${endDate}`
    }
    const response = await axios.get(analyticsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return []
    }
  },
  async getAppLeadsSDKStats (appId, taxonId, taxonRegion, startDate, endDate) {
    
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let analyticsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/analytics/leadssdk`
    let haveQueryParams = false
    if (startDate && endDate) {
      haveQueryParams = true
      analyticsURL = `${analyticsURL}?startDate=${startDate}&endDate=${endDate}`
    }

    if (taxonId) {
      const nextParamChar = haveQueryParams ? '&' : '?'
      analyticsURL = `${analyticsURL}${nextParamChar}taxon=${taxonId}`
      haveQueryParams = true
    }

    if (taxonRegion) {
      const nextParamChar = haveQueryParams ? '&' : '?'
      analyticsURL = `${analyticsURL}${nextParamChar}taxonRegion=${taxonRegion}`
    }

    const response = await axios.get(analyticsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return []
    }
  },
  async getAppBillingStats (appId, startDate, endDate) {
    
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let analyticsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/analytics/billing`
    if (startDate && endDate) {
      analyticsURL = `${analyticsURL}?startDate=${startDate}&endDate=${endDate}`
    }
    const response = await axios.get(analyticsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return []
    }
  },
  async setAppDomainVerified (appId, appKey, callback) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const appVerifiedUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/verified`
    const body = {appKey: appKey, isVerified: true}
    axios.post(appVerifiedUrl, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    }).then(function (response) {
      // handle success
      if (response && response.status === 200 && response.data && response.data.status === 'success') {
        callback(response.data)
      } else {
        callback(null)
      }
    })
    .catch(function (/*error*/) {
      // handle error
      // console.log(error)
      callback(null)
    })
  },
  async setSyncCatalogProductMetaData (appId, synchingEnabled, onlySyncNewItems) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const postURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/catalog/syncmode`
    const enabledBool = synchingEnabled == true
    const onlySyncNewBool = onlySyncNewItems == true
    const body = {enabled: enabledBool, onlySyncNewItems: onlySyncNewBool}
    const response = await axios.post(postURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.status === 200) {
      return response.data
    } else {
      return null
    }

  },
  async getFilteredCatalogItems (appId, taxonId, region, pageSize, lastItem, orderByField, sortDescending, filters) {

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const filteredItemsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/filtereditems`

    const body = {
      pageSize: pageSize,
      taxonId: taxonId,
      dataRegion: region,
      filters: filters,
      orderByField: orderByField,
      sortDescending: sortDescending
    }

    if (lastItem) {
      body.startAfterId = lastItem.id
    }

    const res = await axios.post(filteredItemsURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return {items: {}, totalItemsCount: 0}
    }
  },
  async getPaginatedCatalogItems (appId, pageSize, lastItem, lastItemCount, orderByField, sortDescending, filters) {

    // Properties of filter
    // groupId, taxonId, importGroupFilter, taxonFilter, missingInFeedFilter, metaFilterCode, metaFilterValue, regionCode, insightDomainFilter, checkMissing, urlFilter, nameSlugFilter, filterOnNoRegionalData, filterOnInsightsEnabled, filterOnRefreshJobId
    const groupId = filters ? filters.groupId : null;
    const taxonId = filters ? filters.taxonId : null;
    const importGroupFilter = filters ? filters.importGroupFilter : null;
    const taxonFilter = filters ? filters.taxonFilter : null;
    const missingInFeedFilter = filters ? filters.missingInFeedFilter : null;
    const feedHashChangedFilter = filters ? filters.filterOnFeedHashChanged : null;
    const metaFilterCode = filters ? filters.metaFilterCode : null;
    const metaFilterValue = filters ? filters.metaFilterValue : null;
    const regionCode = filters ? filters.regionCode : null;
    const insightDomainFilter = filters ? filters.insightDomainFilter : null;
    const checkMissing = filters ? filters.checkMissing : null;
    const urlFilter = filters ? filters.urlFilter : null;
    const nameSlugFilter = filters ? filters.nameSlugFilter : null;
    const filterOnNoRegionalData = filters ? filters.filterOnNoRegionalData : null;
    const filterOnInsightsEnabled = filters ? filters.filterOnInsightsEnabled : null;
    const filterOnRefreshJobId = filters ? filters.filterOnRefreshJobId : null;
    const filterOnComparisonNoApproved = filters ? filters.filterComparisonNotApproved : null;
    const filterOnNotScraped = filters ? filters.filterOnNotScraped : null;
    const filterOnHasNewValues = filters ? filters.filterOnHasNewValues : null;

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let paginatedOptionsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/?pageSize=${pageSize}`
    // console.log(`Calling pagination with last option being ${JSON.stringify(lastItem)}`)
    if (taxonId) {
      paginatedOptionsURL = `${paginatedOptionsURL}&taxon=${taxonId}`
    }
    if (groupId) {
      paginatedOptionsURL = `${paginatedOptionsURL}&group=${groupId}`
    }
    if (lastItem) {
      paginatedOptionsURL = `${paginatedOptionsURL}&startAfter=${lastItem.id}`
    }
    if (lastItemCount) {
      paginatedOptionsURL = `${paginatedOptionsURL}&lastItemCount=${lastItemCount}`
    }
    if (orderByField) {
      // console.log(`orderByField = ${orderByField}`)
      paginatedOptionsURL = `${paginatedOptionsURL}&sortBy=${orderByField}`
    }
    if (sortDescending) {
      paginatedOptionsURL = `${paginatedOptionsURL}&desc=true`
    }
    if (importGroupFilter) {
      paginatedOptionsURL = `${paginatedOptionsURL}&importGroupFilter=${importGroupFilter}`
    }
    if (taxonFilter) {
      paginatedOptionsURL = `${paginatedOptionsURL}&taxonFilter=${taxonFilter}`
    }
    if (urlFilter) {
      paginatedOptionsURL = `${paginatedOptionsURL}&urlFilter=${urlFilter}`
    }
    if (nameSlugFilter) {
      paginatedOptionsURL = `${paginatedOptionsURL}&nameSlugFilter=${nameSlugFilter}`
    }
    if (missingInFeedFilter === true) {
      paginatedOptionsURL = `${paginatedOptionsURL}&missingInFeedFilter=true`
    }
    if (feedHashChangedFilter === true) {
      paginatedOptionsURL = `${paginatedOptionsURL}&feedHashChangedFilter=true`
    }
    if (filterOnNoRegionalData === true) {
      paginatedOptionsURL = `${paginatedOptionsURL}&noRegionalDataFilter=true`
    }
    if (filterOnComparisonNoApproved === true) {
      paginatedOptionsURL = `${paginatedOptionsURL}&notApprovedForComparison=true`
    }
    if (filterOnNotScraped === true) {
      paginatedOptionsURL = `${paginatedOptionsURL}&notScraped=true`
    }
    if (filterOnHasNewValues === true) {
      paginatedOptionsURL = `${paginatedOptionsURL}&hasNewValues=true`
    }
    if (filterOnInsightsEnabled === true || filterOnInsightsEnabled === false) {
      paginatedOptionsURL = `${paginatedOptionsURL}&insightsEnabledFilter=${filterOnInsightsEnabled}`
    }
    if (filterOnRefreshJobId) {
      paginatedOptionsURL = `${paginatedOptionsURL}&refreshJobFilter=${filterOnRefreshJobId}`
    }
    if (insightDomainFilter && regionCode) {
      paginatedOptionsURL = `${paginatedOptionsURL}&insightDomainFilter=${insightDomainFilter}`

      if (checkMissing) {
        paginatedOptionsURL = `${paginatedOptionsURL}&missingInDomain=true`
      }
    }
    if (metaFilterCode && metaFilterValue && regionCode) {
      paginatedOptionsURL = `${paginatedOptionsURL}&metaCode=${metaFilterCode}&metaValue=${metaFilterValue}`
    }
    if (regionCode) {
      paginatedOptionsURL = `${paginatedOptionsURL}&regionCode=${regionCode}`
    }
    // if (filterOnNewValues) {
    //   paginatedOptionsURL = `${paginatedOptionsURL}&filterNewValues=true`
    // }
    // if (typeof returnTotal === 'boolean') {
    //   paginatedOptionsURL = `${paginatedOptionsURL}&returnTotal=${returnTotal}`
    // }
    const res = await axios.get(paginatedOptionsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return {items: {}, totalItemsCount: 0}
    }
  },
  async getBulkItems (appId, items) {
    // req.body.items : Array<{id: string, scrapeState: string, optionId?: string, taxonId?: string}>
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const bulkUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/bulk`

    const body = {
      items: items
    }

    const response = await axios.post(bulkUrl, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  // async getCatalogItem (appId, itemId) {
  //   const token = await AuthUtil.getCurrenUserIdToken()
  //   let catalogItemURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}`
  //   const res = await axios.get(catalogItemURL, {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Authorization': `Bearer ${token}`,
  //     }
  //   })

  //   if (res && res.status === 200 && res.data) {
  //     return res.data
  //   } else {
  //     return null
  //   }
  // },
  async getInsightRegions (appId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let insightRegionsURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/insights`
    const res = await axios.get(insightRegionsURL, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async getInsightsData (appId, itemKeys, regionCode) {

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let insightsDataURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/iteminsights`
    const body = {itemKeys: itemKeys, regionCode: regionCode}
    const res = await axios.post(insightsDataURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async deleteInsightData (appId, itemId, itemKey, regionCode) {
    ///developer/apps/:app/insights/:region/items/:item
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteItemInsightsDataUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/insights/${regionCode}/items/${itemKey}`
    
    const res = await axios.delete(deleteItemInsightsDataUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async disableItemInsights (appId, itemId) {
    ///developer/apps/:app/insights/:region/items/:item
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const updateItemUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}`
    const payload = {
      clearInsights: true
    }

    const res = await axios.post(updateItemUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async enableItemInsights (appId, itemId) {
    ///developer/apps/:app/insights/:region/items/:item
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const updateItemUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}`
    const payload = {
      insightsEnabled: true
    }

    const res = await axios.post(updateItemUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
  },
  async bulkUpdateInsightsEnabledState (appId, itemIds, isEnabled) {
    
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()

    const updateItemUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/enableinsights`
    const payload = {
      items: itemIds,
      insightsEnabled: isEnabled
    }

    const res = await axios.post(updateItemUrl, payload, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }

  },
  async addTargetInsightURLs (appId, regionCode, itemURLs) {
    // console.log(`TODO submit urls ${JSON.stringify(itemURLs)} to app ${appId} in region ${regionCode}`)

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let insightsDataURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/insights/${regionCode}/targeturls`
    const body = {urls: itemURLs}

    try {
      const res = await axios.post(insightsDataURL, body, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return {data: res.data}
      } else {
        return {data: null}
      }
    } catch (error) {
      // console.log(`${JSON.stringify(error)}`)
      let errorMessage = 'Unexpected response when adding target urls'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async indexItemForSearch (appId, itemId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let indexItemURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}/typesenseindex`
    const body = {}

    try {
      const res = await axios.post(indexItemURL, body, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return {data: res.data}
      } else {
        return {data: null}
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async syncItemForSearch (appId, itemId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let indexItemURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}/typesensesync`
    const body = {}

    try {
      const res = await axios.post(indexItemURL, body, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return {data: res.data}
      } else {
        return {data: null}
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async deleteItemForSearch (appId, itemId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let indexItemURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}/typesensedelete`
    const body = {}

    try {
      const res = await axios.post(indexItemURL, body, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return {data: res.data}
      } else {
        return {data: null}
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async getSearchConfig (appId) {
    // /developer/apps/:app/tsconfig
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let typesenseConfigURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesenseconfig`

    try {
      const res = await axios.get(typesenseConfigURL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async updateCollectionSchema (appId, schemaChanges) {
    // /developer/apps/:app/tsconfig
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let typesenseConfigURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesenseconfig`

    const payload = {}
    if (schemaChanges.itemDataSchema) {
      payload.itemDataSchema = schemaChanges.itemDataSchema
    }
    if (schemaChanges.optionDataSchema) {
      payload.optionDataSchema = schemaChanges.optionDataSchema
    }
    if (schemaChanges.contentVector) {
      payload.contentVector = schemaChanges.contentVector
    }
    if (schemaChanges.embeddings) {
      payload.embeddings = schemaChanges.embeddings
    }

    try {
      const res = await axios.post(typesenseConfigURL, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async toggleSortableFieldInCollectionSchema (appId, fieldName) {
    // /developer/apps/:app/tsconfig
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const typesenseConfigURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesense/togglesortable`

    try {
      const res = await axios.post(typesenseConfigURL, {fieldName: fieldName}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async updateAppCluster (appId, clusterConfig) {
    // /developer/apps/:app/tsconfig
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let typesenseConfigURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesensecluster`

    try {
      const res = await axios.post(typesenseConfigURL, {
        host: clusterConfig.host,
        port: clusterConfig.port,
        protocol: clusterConfig.protocol,
        apiKey: clusterConfig.apiKey,
        searchApiKey: clusterConfig.searchApiKey,
        connectionTimeoutSeconds: clusterConfig.connectionTimeoutSeconds
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async clearAppCluster (appId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let typesenseConfigURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesensecluster`

    try {
      const res = await axios.delete(typesenseConfigURL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async getSearchCollectionSchema (appId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let typesenseConfigURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesensecollectionsschema`

    try {
      const res = await axios.get(typesenseConfigURL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async initSearchConfig (appId, appKey, region) {
    // /developer/apps/:app/tsconfig
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let typesenseConfigURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesenseinit`

    try {
      const payload = {
        appKey: appKey
      }
      if (region) {
        payload.region = region
      }
      const res = await axios.post(typesenseConfigURL, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async enableSearch (appId, appKey) {
    // /developer/apps/:app/tsconfig
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let typesenseConfigURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesenseenable`

    try {
      const res = await axios.post(typesenseConfigURL, {appKey: appKey}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async disableSearch (appId) {
    // /developer/apps/:app/tsconfig
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let typesenseConfigURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesensedisable`

    try {
      const res = await axios.post(typesenseConfigURL, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async saveSearchPreset (appId, params) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let typesenseConfigURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesensepresets`

    const payload = {
      params: params
    }

    try {
      const res = await axios.post(typesenseConfigURL, payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async deleteSearchPreset (appId, presetId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let typesenseConfigURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesensepresets/${presetId}`

    try {
      const res = await axios.delete(typesenseConfigURL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async publishSearchPreset (appId, presetId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let typesensePresetPublishURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesensepresets/${presetId}/publish`

    try {
      const res = await axios.post(typesensePresetPublishURL, {}, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async getSearchPresets (appId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let typesenseConfigURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/typesensepresets`

    try {
      const res = await axios.get(typesenseConfigURL, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response indexing item for search'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async loadProductMetaData (appId, itemId, scraperRegion) {
    try {
      const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
      let postURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}/loadmetadata`
      const body = {}
      if (scraperRegion) {
        body.scraperRegion = scraperRegion
      }

      const res = await axios.post(postURL, body, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      // console.log(`${JSON.stringify(error)}`)
      let errorMessage = 'Unexpected response when loading product data'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async bulkInvalidateProductMetaData (appId, itemIds, scraperRegion) {
    try {
      const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
      // developer/apps/:app/catalog/invalidatemetadata
      let postURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/catalog/invalidatemetadata`
      const body = {
        items: itemIds,
        regionCode: scraperRegion
      }

      const res = await axios.post(postURL, body, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = 'Unexpected response when loading product data'
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async setItemInsightURL (appId, itemId, targetHostname, targetURL, targetProductKey, regionCode) {
    // console.log(`TODO submit urls ${JSON.stringify(itemURLs)} to app ${appId} in region ${regionCode}`)

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let postURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}/insighturl`
    const body = {targetHostname: targetHostname}
    if (targetURL) {
      body.targetURL = targetURL
    }
    if (targetProductKey) {
      body.targetProductKey = targetProductKey
    }
    if (regionCode) {
      body.targetRegion = regionCode
    }

    const res = await axios.post(postURL, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })
    // console.log(`Should call ${postURL} with body ${JSON.stringify(body)}`)
    // const res = null

    if (res && res.status === 200 && res.data) {
      return res.data
    } else {
      return null
    }
    
  },
  async refreshItemInsights (appId, regionCode, itemId) {
    // console.log(`TODO submit urls ${JSON.stringify(itemURLs)} to app ${appId} in region ${regionCode}`)

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let insightsDataURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/insights/${regionCode}/refreshitem`
    const body = {itemId: itemId}

    try {
      const res = await axios.post(insightsDataURL, body, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = `Unexpected response when refreshing item ${itemId}`
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async latestItemInsights (appId, regionCode, itemIds) {
    // console.log(`TODO submit urls ${JSON.stringify(itemURLs)} to app ${appId} in region ${regionCode}`)
    //!req.body.itemId || !req.body.regionalProductKey || !req.body.targetDomains

    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    let insightsDataURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/insights/${regionCode}/iteminsightdata`
    const body = {
      items: itemIds
    }

    try {
      const res = await axios.post(insightsDataURL, body, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
        }
      })

      if (res && res.status === 200 && res.data) {
        return res.data
      } else {
        return null
      }
    } catch (error) {
      let errorMessage = `Unexpected response when refreshing items ${JSON.stringify(itemIds)}`
      if (error.response && error.response.data && error.response.data.error) {
        errorMessage = error.response.data.error
      }
      return errorMessage
    }
  },
  async bulkDeleteCatalogItems (appId, items, onlyDeleteItemServices) {
    // req.body.items : Array<{id: string, scrapeState: string, optionId?: string, taxonId?: string}>
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const bulkDeleteUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/delete`

    const body = {
      items: items
    }

    if (onlyDeleteItemServices) {
      body.onlyDeleteItemServices = true
    }

    const response = await axios.post(bulkDeleteUrl, body, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async deleteCatalogItem (appId, itemId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const deleteUrl = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}`

    const response = await axios.delete(deleteUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async acceptNewTaxonMappingsForImportGroup (appId, importGroupId) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const acceptNewTaxonURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/acceptnewtaxon`

    const response = await axios.post(acceptNewTaxonURL, {importGroupId: importGroupId}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  },
  async acceptNewImportGroupForItem (appId, itemId, oldImportGroup, newImportGroup) {
    const token = await AuthUtil.getCurrenUserIdToken()
    const appCheckToken = await AuthUtil.getAppCheckToken()
    const acceptNewImportGroupURL = `${Constants.API_BASE_PATH}/developer/apps/${appId}/items/${itemId}/acceptnewimportgroup`

    const response = await axios.post(acceptNewImportGroupURL, {importGroupId: oldImportGroup, newImportGroupId: newImportGroup}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
        'X-Firebase-AppCheck': appCheckToken
      }
    })

    if (response && response.data) {
      return response.data
    } else {
      return null
    }
  }
}
